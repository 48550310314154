import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form, Field } from 'formik';
import { withStyles, FormControl, lighten, InputAdornment, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useTranslation } from 'react-i18next';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';

import Button from '../../../shared/Button';
import { submitCampaignPageForm, subscribeNewsletter } from '../../../../utils/analyticUtils';

import { useCreateCampaignSubscription } from '../../../hooks/useCreateCustobarSubscription';
import { getWindowPathName, isSSR } from '../../../../utils/windowUtils';
import { getUtmCampaignFromPath } from '../../../../utils/custobarUtils';

const styles = theme => ({
  root: {},
  form: {},
  formField: {
    width: '100%',
    marginBottom: '0',
    '& > div': {
      borderRadius: '8px',
      height: '48px',
      borderColor: ({ pageData }) => {
        const primaryColor = get(pageData, 'mainTheme.themeStyles.primaryBrandColor');
        return isEmpty(primaryColor) ? null : lighten(primaryColor, 0.3);
      },
      backgroundColor: ({ pageData }) => {
        const primaryColor = get(pageData, 'mainTheme.themeStyles.primaryBrandColor');
        return isEmpty(primaryColor) ? null : lighten(primaryColor, 0.98);
      },
    },
  },
  button: {
    borderRadius: '9px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  buttonIcon: {
    color: 'inherit',
    marginLeft: theme.spacing(1),
  },
  text: {
    color: ({ pageData }) => {
      const appearance = get(pageData, 'appearance');
      const customThemeStyle = get(pageData, 'customThemeStyle', null);
      const lightColor = get(theme, 'palette.pageTemplates.campaignPage.colors.lightColor', '#fff');

      if (appearance !== 'Light' && isEmpty(customThemeStyle)) {
        return lightColor;
      }
      return null;
    },
  },
});

function CustobarSubscriptionForm({ classes, className, pageData }) {
  const [isSuccess, setIsSuccess] = useState(false);
  const monopolyUrl = get(pageData, 'featuredProducts.relatedProduct.attributes.monopoly_url.value', null);

  const newsletter = get(pageData, 'newsletter');
  const mailingLists = get(newsletter, 'formType.custobarMailingLists');

  const { t } = useTranslation();

  const subscribePage = getWindowPathName();
  const utmCampainID = getUtmCampaignFromPath();

  const { mutateAsync: createCustobarSubscription } = useCreateCampaignSubscription();

  const handleFormSubmit = useCallback(
    async ({ emailAddress }, { resetForm }) => {
      try {
        const emailValue = document.getElementById('emailAddress').value;

        if (!isEmpty(emailValue)) {
          const { data: response } = await createCustobarSubscription({
            emailAddress,
            mailingLists,
            subscribePage,
            utmCampainID,
          });
          if (response.message === 'Success') {
            setIsSuccess(true);
          }
          subscribeNewsletter();
          resetForm();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [createCustobarSubscription, mailingLists, subscribePage, utmCampainID],
  );

  const handleBtnOnClick = useCallback(() => {
    if (!isEmpty(monopolyUrl) && !isSSR()) {
      window.open(monopolyUrl, '_blank');
    }
    submitCampaignPageForm();
  }, [monopolyUrl]);

  const initialValues = useMemo(
    () => ({
      emailAddress: '',
    }),
    [],
  );

  const validationSchema = useMemo(() => {
    if (!isEmpty(monopolyUrl)) {
      return Yup.object().shape({
        emailAddress: Yup.string().email(t('CustobarSubscriptionForm.errors.emailAddress.invalid')),
      });
    }
    return Yup.object().shape({
      emailAddress: Yup.string()
        .email(t('CustobarSubscriptionForm.errors.emailAddress.invalid'))
        .required(t('CustobarSubscriptionForm.errors.emailAddress.required')),
    });
  }, [t, monopolyUrl]);

  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant="body2" className={classes.text}>
        {t(`CampaignPage.Newsletter.EmailAddress.Label`)}
      </Typography>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
        {({ isSubmitting, isSubmitted, isValidating, isValid, errors }) => {
          return (
            <Form id="form">
              <FormControl
                className={classes.form}
                component="fieldset"
                error={Boolean(errors.email)}
                fullWidth
                required={isEmpty(monopolyUrl)}>
                <Field
                  id="emailAddress"
                  type="email"
                  name="emailAddress"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon className={classes.inputStartIcon} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t(`CampaignPage.Newsletter.EmailAddress.Placeholder`)}
                  title={t(`CampaignPage.Newsletter.EmailAddress.Placeholder`)}
                  component={TextField}
                  classes={{ root: classes.formField }}
                  disabled={isSubmitted}
                  required={isEmpty(monopolyUrl)}
                />
                {isValid && isSuccess && (
                  <Typography variant="body2" className={classes.text}>
                    {t(`CampaignPage.Newsletter.ThankYouMessage`)}
                  </Typography>
                )}
              </FormControl>
              {!isEmpty(monopolyUrl) && (
                <Button className={classes.button} type="submit" onClick={handleBtnOnClick}>
                  {t('CampaignPage.OrderForm.Order.ButtonLabel')}
                  <LaunchIcon className={classes.buttonIcon} />
                </Button>
              )}
              {isEmpty(monopolyUrl) && (
                <Button
                  disabled={isSubmitting || !isValid || isValidating}
                  className={classes.button}
                  type="submit"
                  onClick={handleBtnOnClick}>
                  {t('CampaignPage.OrderForm.Subscribe.ButtonLabel')}
                  <SendOutlinedIcon className={classes.buttonIcon} />
                </Button>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

CustobarSubscriptionForm.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.object,
};

CustobarSubscriptionForm.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(CustobarSubscriptionForm);
