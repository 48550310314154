import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { Container, Typography, lighten, withStyles } from '@material-ui/core';
import RichText from '../../../../shared/RichText';
import BackgroundImage from '../../../../shared/BackgroundImage';
import CloudinaryImage from '../../../../shared/CloudinaryImage';
import ProductMonopolyPrice from '../../../../shared/Products/ProductMonopolyPrice';
import CampaignOrderForm from '../../CampaignOrderForm';
import ProductLabels from '../../../../shared/Products/ProductLabels';

const styles = theme => ({
  root: {
    zIndex: '1',
    position: 'relative',
    overflow: 'hidden',
    paddingTop: ({ pageData }) => {
      const newsletter = get(pageData, 'newsletter');
      return isEmpty(newsletter) ? 0 : theme.spacing(6);
    },
    backgroundColor: ({ pageData }) => {
      const bkImgPublicId = get(pageData, 'backgroundImage.cloudinaryImage.0.public_id');
      const appearance = get(pageData, 'appearance');
      const primaryColor = get(pageData, 'customThemeStyle.primaryBrandColor');

      if (isEmpty(bkImgPublicId)) {
        return appearance === 'Light' ? lighten(primaryColor, 0.9) : '#000';
      }
      return 'transparent';
    },
  },
  container: {},
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: theme.spacing(5),
    },
  },
  imageSide: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '100%',
    maxWidth: '100%',
    padding: theme.spacing(4, 3),

    [theme.breakpoints.up('md')]: {
      flexBasis: '50%',
      maxWidth: '50%',
    },
  },
  productImage: {
    maxHeight: '450px',
  },
  title: {
    textTransform: 'uppercase',
    paddingTop: theme.spacing(3),
  },
  priceWrapper: {
    backgroundColor: '#f0f0f0',
    padding: '6px 12px',
    borderRadius: '64px',
  },
  price: {
    fontSize: '13px',
    color: '#333333',
  },

  informations: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexBasis: '1000%',
    maxWidth: '100%',
    padding: theme.spacing(4),
    backgroundColor: ({ pageData }) => {
      const appearance = get(pageData, 'appearance');
      if (appearance === 'Light') {
        return '#ffffff';
      }
      return '#222222';
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '50%',
      maxWidth: '50%',
      padding: theme.spacing(8),
    },
  },
  fontColor: {
    color: ({ pageData }) => {
      const appearance = get(pageData, 'appearance');
      const customThemeStyle = get(pageData, 'customThemeStyle', null);
      const lightColor = get(theme, 'palette.pageTemplates.campaignPage.colors.lightColor', '#fff');

      if (appearance !== 'Light' && isEmpty(customThemeStyle)) {
        return lightColor;
      }
      return null;
    },
  },
  descripton: {
    fontSize: '0.8rem',
  },
});

function CampaignFeaturedProduct({ classes, className, pageData }) {
  const bkImgAltText = get(pageData, 'backgroundImage.imageAltText');
  const bkImgPublicId = get(pageData, 'backgroundImage.cloudinaryImage.0.public_id');
  // const appearance = get(pageData, 'appearance');
  const content = get(pageData, 'content');

  const product = get(pageData, 'featuredProducts.relatedProduct');

  const name = get(pageData, 'featuredProducts.productName');
  const title = get(pageData, 'title', name);

  const productImage = get(pageData, 'featuredProducts.relatedProduct.heroImage');
  // const fullPath = get(pageData, 'featuredProducts.relatedProduct.fullPath');
  // const attributes = get(pageData, 'featuredProducts.relatedProduct.attributes');
  // const monopolyUrl = get(attributes, 'attributes.monopoly_url.value');

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="xl" disableGutters className={classes.container}>
        <BackgroundImage altText={bkImgAltText} publicId={bkImgPublicId} />
        <div className={classes.wrapper}>
          <div className={classes.imageSide}>
            <ProductLabels productData={product} />
            <CloudinaryImage
              publicId={get(productImage, 'cloudinaryImage.0.public_id')}
              alt="product image"
              width="auto"
              height="380"
            />
            <Typography variant="h1" align="center" className={clsx(classes.title, classes.fontColor)}>
              {title}
            </Typography>
            <div className={classes.priceWrapper}>
              <ProductMonopolyPrice productData={product} classes={{ price: classes.price }} />
            </div>
          </div>

          <div className={classes.informations}>
            <CampaignOrderForm
              pageData={pageData}
              classes={{ title: classes.fontColor, description: classes.fontColor }}
            />
            <RichText
              textData={content}
              classes={{
                paragraph: clsx(classes.descripton, classes.fontColor),
                heading2: classes.fontColor,
                heading3: classes.fontColor,
                heading4: classes.fontColor,
                heading5: classes.fontColor,
                heading6: classes.fontColor,
                hyperlink: classes.linkFontColor,
              }}
              target="_blank"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

CampaignFeaturedProduct.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CampaignFeaturedProduct.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(CampaignFeaturedProduct);
