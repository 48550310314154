import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { Typography, withStyles } from '@material-ui/core';
import CustobarSubscriptionForm from '../CustobarSubscriptionForm';

const styles = theme => ({
  root: {},
  form: {
    margin: theme.spacing(2, 0),
  },
  title: {
    textTransform: 'uppercase',
  },
  description: {},
});

function CampaignOrderForm({ classes, className, pageData }) {
  const { t } = useTranslation();

  const monopolyUrl = get(pageData, 'featuredProducts.relatedProduct.attributes.monopoly_url.value', null);
  const newsletter = get(pageData, 'newsletter');

  if (isEmpty(newsletter)) {
    return null;
  }
  return (
    <div className={clsx(classes.root, className)} id="order_form">
      {!isEmpty(monopolyUrl) && (
        <div className={classes.order}>
          <Typography variant="h3" className={classes.title}>
            {t('CampaignPage.OrderForm.Order.Title')}
          </Typography>
          <Typography className={classes.description}>{t('CampaignPage.OrderForm.Order.Text')}</Typography>
          <CustobarSubscriptionForm className={classes.form} pageData={pageData} />
        </div>
      )}
      {isEmpty(monopolyUrl) && (
        <div className={classes.subscribe}>
          <Typography variant="h3" className={classes.title}>
            {t('CampaignPage.OrderForm.Subscribe.Title')}
          </Typography>
          <Typography className={classes.description}>{t('CampaignPage.OrderForm.Subscribe.Text')}</Typography>
          <CustobarSubscriptionForm pageData={pageData} className={classes.form} />
        </div>
      )}
    </div>
  );
}

CampaignOrderForm.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CampaignOrderForm.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(CampaignOrderForm);
