import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { graphql } from 'gatsby';
import { withStyles } from '@material-ui/core';

import Footer from '../../../components/layout/Footer';
import SeoFields from '../../../components/page/SeoFields';
import CustomStyleFontFamilyLinks from '../../../components/page/CustomStyleFontFamilyLinks';
import SiteNotification from '../../../components/page/SiteNotifications';
import AnalyticsModule from '../../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../../components/shared/ThemeStyle/withSecondaryTheme';
import PlayableIframeScript from '../../../components/page/PlayableIframeScript';
import PageBrowseTracker from '../../../components/page/PageBrowseTracker';
import CampaignFeaturedProduct from '../../../components/page/Campaign/CampaignFeaturedProduct';

import useInitI18next from '../../../components/hooks/useInitI18next';
import useInitLuxon from '../../../components/hooks/useInitLuxon';
import CampaignHeaderElement from '../../../components/page/Campaign/CampaignHeaderElement';
import CampaignContentLifts from '../../../components/page/Campaign/CampaignContentLifts';
import RelatedCampaigns from '../../../components/page/Campaign/CampaignRelatedCampaigns';
import CampaignNewsletter from '../../../components/page/Campaign/CampaignNewsletter';
import { pushFacebookCategory } from '../../../utils/analyticUtils';

const styles = theme => ({
  root: {
    display: 'block',
  },
  notifications: {
    marginTop: theme.spacing(4),
  },
});

function LandingPageTemplate({ classes, data }) {
  const pageData = get(data, 'contentfulCampaign');
  const analytics = [get(pageData, 'analytics', get(pageData, 'mainTheme.analytics.0'))];

  useEffect(() => {
    pushFacebookCategory(get(pageData, 'metaPixelType', ''));
  }, [pageData]);

  useInitI18next();
  useInitLuxon();

  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <SeoFields pageData={pageData} />
      <CustomStyleFontFamilyLinks pageData={pageData} />
      <PlayableIframeScript pageData={pageData} />
      <AnalyticsModule data={analytics} />

      <main>
        <SiteNotification pageData={pageData} className={classes.notifications} />
        <CampaignHeaderElement pageData={pageData} />
        <CampaignFeaturedProduct pageData={pageData} />
        <CampaignContentLifts pageData={pageData} />
        <RelatedCampaigns pageData={pageData} />
        <CampaignNewsletter pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

LandingPageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

LandingPageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export const pageQuery = graphql`
  query CampaignPageTemplate($pageId: String!) {
    contentfulCampaign(id: { eq: $pageId }) {
      id
      contentful_id
      title
      shortDescription
      languageCode
      fullPath
      internal {
        type
      }
      backgroundImage {
        ...ContentImageModuleFragment
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }

      primaryChannel {
        ...PrimaryChannelFragment
      }

      topNavigation {
        ...TopNavigationFragment
      }
      mainTheme {
        ...themeFragment
      }
      analytics {
        ...AnalyticsModuleFragment
      }

      newsletter {
        ...ContentFormModuleFragment
      }
      content {
        ...ContentFragment
      }

      contentLifts {
        ...ContentContentLiftModuleFragment
      }

      featuredProducts {
        ...ContentProductReferenceFragment
      }

      relatedCampaigns {
        id
        contentful_id
        title
        shortDescription
        languageCode
        fullPath
        featuredProducts {
          ...ContentProductReferenceFragment
        }
      }
      customThemeStyle {
        ...ThemeStyleModuleFragment
      }
      appearance
      metaPixelType
    }
  }
`;

export default withSecondaryTheme(withStyles(styles)(LandingPageTemplate));
